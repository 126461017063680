import React, { useState, useEffect, useCallback } from "react";
import {
    StyleSheet,
    Text,
    View,
    Button,
    Image,
    BackHandler,
    StatusBar,
    TouchableOpacity,
    Platform,
    Alert,
} from "react-native";
import { Camera } from "expo-camera";
import { Ionicons, FontAwesome, AntDesign } from "@expo/vector-icons";
import { getItem } from "../Utility";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";

export default function TakePictureScreen({ navigation }) {
    const [hasCameraPermission, setHasCameraPermission] = useState(null);
    const [camera, setCamera] = useState(null);
    const [image, setImage] = useState(null);
    const [type, setType] = useState(Camera.Constants.Type.front);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [hasPhoto, setHasPhoto] = useState(false);
    const [username, setUsername] = useState("");
    const [tapType, setTapType] = useState("");
    const [isShowCamera, setIsShowCamera] = useState(true);
    const isFocused = useIsFocused();

    useFocusEffect(
        useCallback(() => {
            // console.log("TakePicture Screen was focused");
            setIsShowCamera(true);
            return () => {
                setIsShowCamera(false);
                // console.log("TakePicture Screen was unfocused");
            };
        }, [])
    );

    useEffect(() => {
        (async () => {
            const cameraStatus = await Camera.requestCameraPermissionsAsync();
            setHasCameraPermission(cameraStatus.status === "granted");
        })();

        // function handleBackButton() {
        //     navigation.pop();
        //     return true;
        // }

        // const backHandler = BackHandler.addEventListener(
        //     "hardwareBackPress",
        //     handleBackButton
        // );

        getItem("tapType")
            .then((result) => {
                return result;
            })
            .then((result) => {
                setTapType(result);
            })
            .catch(() => {
                navigation.navigate("Home");
            });

        getItem("username")
            .then((result) => {
                return result;
            })
            .then((result) => {
                if (result) {
                    setUsername(result);
                }
            });

        // return () => backHandler.remove();
        return () => {};
    }, []);

    const takePicture = async () => {
        if (camera) {
            const data = await camera.takePictureAsync(null);
            setImage(data.uri);
            setHasPhoto(true);
        }
    };

    const DataURIToBlob = function (dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const sendData = function () {
        let localUri = image;
        let filename = localUri.split("/").pop();

        // Infer the type of the image
        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;

        // Upload the image using the fetch and FormData APIs
        let formData = new FormData();
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            body: formData,
        };
        // Assume "photo" is the name of the form field the server expects
        if (Platform.OS == "web") {
            const file_image = DataURIToBlob(localUri);
            const file_type1 = localUri.split(";");
            const file_type2 = file_type1[0].split(":");
            console.log(file_type2);
            const file_name =
                typeof file_type2[1] != "undefined"
                    ? file_type2[1].replace("/", ".")
                    : "image.png";
            console.log(file_name);
            formData.append("photo", file_image, file_name.toString());
            options = {
                method: "POST",
                body: formData,
            };
        } else {
            formData.append("photo", { uri: localUri, name: filename, type });
        }
        formData.append("username", username);
        formData.append("type", tapType);
        formData.append("flip", false);

        setButtonDisabled(true);
        fetch(
            "https://lgi.my.id/sso/index.php/attendance/home/insert_photo",
            options
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setButtonDisabled(false);
                setHasPhoto(false);
                navigation.pop();
                return response;
            })
            .catch((error) => {
                console.log(error);
                Alert.alert("Error", error.message);
                setButtonDisabled(false);
            });
    };

    if (hasCameraPermission === false) {
        return <Text>No access to camera</Text>;
    }

    return (
        <View style={{ flex: 1, backgroundColor: "black" }}>
            <StatusBar
                backgroundColor="#000"
                barStyle="light-content"
                translucent={true}
            />
            <View
                style={{
                    display: !hasPhoto ? "flex" : "none",
                    flex: 1,
                    flexDirection: "row",
                }}
            >
                {isFocused && isShowCamera ? (
                    <Camera
                        ref={(ref) => setCamera(ref)}
                        style={styles.fixedRatio}
                        type={type}
                        ratio={"1:1"}
                    />
                ) : (
                    <View></View>
                )}
            </View>
            {image && (
                <View
                    style={{
                        flex: 1,
                        zIndex: 1,
                        display: hasPhoto ? "flex" : "none",
                    }}
                >
                    <Image source={{ uri: image }} style={{ flex: 1 }} />
                </View>
            )}
            <View
                style={{
                    display: !hasPhoto ? "flex" : "none",
                    flex: 0.4,
                    zIndex: 3,
                    backgroundColor: "#000",
                    justifyContent: "flex-end",
                }}
            >
                <View
                    style={{
                        zIndex: 2,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        flexDirection: "row",
                    }}
                >
                    <View
                        style={{
                            justifyContent: "center",
                            width: 50,
                            marginRight: 45,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 36,
                                color: "white",
                            }}
                        ></Text>
                    </View>
                    <TouchableOpacity
                        style={{
                            justifyContent: "center",
                            opacity: buttonDisabled ? 0.5 : 1,
                        }}
                        onPress={() => {
                            takePicture();
                        }}
                        disabled={buttonDisabled}
                        pointerEvents={buttonDisabled ? "none" : "all"}
                    >
                        <Ionicons
                            name="ios-radio-button-on"
                            size={76}
                            color="white"
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            justifyContent: "center",
                            marginLeft: 45,
                            width: 50,
                        }}
                        onPress={() => {
                            setType(
                                type === Camera.Constants.Type.back
                                    ? Camera.Constants.Type.front
                                    : Camera.Constants.Type.back
                            );
                        }}
                    >
                        {/* <Ionicons name="ios-repeat" size={36} color="white" /> */}
                    </TouchableOpacity>
                </View>
            </View>
            <View
                style={{
                    display: hasPhoto ? "flex" : "none",
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        alignSelf: "stretch",
                    }}
                >
                    <TouchableOpacity
                        onPress={() => {
                            sendData();
                        }}
                        disabled={buttonDisabled}
                        style={
                            buttonDisabled
                                ? styles.buttonOpacity
                                : styles.button
                        }
                        pointerEvents={buttonDisabled ? "none" : "all"}
                    >
                        <AntDesign name="check" size={28} color="white" />
                        <Text style={styles.buttonText}>Submit</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            setHasPhoto(false);
                        }}
                        disabled={buttonDisabled}
                        style={
                            buttonDisabled
                                ? styles.buttonOpacityRed
                                : styles.buttonRed
                        }
                        pointerEvents={buttonDisabled ? "none" : "all"}
                    >
                        <AntDesign name="close" size={28} color="white" />
                        <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    cameraContainer: {
        flex: 1,
        flexDirection: "row",
    },
    fixedRatio: {
        flex: 1,
        aspectRatio: 1,
    },
    container: {
        backgroundColor: "#ffffff",
        flex: 1,
    },
    rightContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    rightIcon: {
        marginHorizontal: 20,
    },
    textHeader: {
        fontSize: 20,
        color: "#fff",
        marginHorizontal: 15,
        fontWeight: "bold",
    },
    containerForm: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    logoImg: {
        width: 95,
        height: 90,
        backgroundColor: "#fff",
        overflow: "hidden",
        borderRadius: 50,
        marginVertical: 10,
    },
    logoText: {
        fontSize: 18,
        fontWeight: "300",
        color: "#333333",
    },
    containerFormButton: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    button: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#1a73e9",
        borderRadius: 4,
        margin: 20,
        marginBottom: 25,
        marginRight: 5,
        paddingVertical: 13,
        marginTop: 0,
    },
    buttonRed: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#fd1c00",
        borderRadius: 4,
        margin: 20,
        marginBottom: 25,
        marginLeft: 5,
        paddingVertical: 13,
        marginTop: 3,
    },
    buttonText: {
        fontSize: 18,
        fontWeight: "500",
        color: "#ffffff",
        textAlign: "center",
        marginLeft: 10,
    },
    buttonOpacity: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#1a73e9",
        borderRadius: 4,
        margin: 20,
        marginBottom: 25,
        marginRight: 5,
        paddingVertical: 13,
        opacity: 0.5,
    },
    buttonOpacityRed: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#fd1c00",
        borderRadius: 4,
        margin: 20,
        marginBottom: 25,
        marginLeft: 5,
        paddingVertical: 13,
        opacity: 0.5,
    },
});
