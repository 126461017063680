// Report.js
import { useFocusEffect } from "@react-navigation/core";
import React, { Component, useState, useCallback, useEffect } from "react";
import {
    FlatList,
    View,
    Text,
    BackHandler,
    StatusBar,
    Button,
    ScrollView,
} from "react-native";
import { Ionicons, AntDesign, Entypo } from "@expo/vector-icons";
import { getItem, httpBuildQuery, setItem } from "../Utility";

export default function ReportScreen({ navigation }) {
    const [username, setUsername] = useState("");
    const [period, setPeriod] = useState(0);
    const [firstDate, setFirstDate] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [prevDisabledPeriod, setPrevDisabledPeriod] = useState(-999);

    useFocusEffect(
        useCallback(() => {
            // console.log("Report Screen was focused");

            getItem("username").then(function (result) {
                setUsername(result);
                getAttendance(result, period);
            });

            return () => {
                // console.log("Report Screen was unfocused");
            };
        }, [])
    );

    useEffect(() => {
        // function handleBackButton() {
        //     navigation.pop();
        //     return true;
        // }

        // const backHandler = BackHandler.addEventListener(
        //     "hardwareBackPress",
        //     handleBackButton
        // );

        // return () => backHandler.remove();
        return () => {};
    }, [navigation]);

    const getAttendance = function (username, period) {
        setLoading(true);
        fetch(
            "https://lgi.my.id/sso/index.php/attendance/home/get_attendance",
            {
                method: "POST",
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: httpBuildQuery({
                    idemployee: username,
                    period: period,
                    _: Date.now(),
                }),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.valid === true) {
                    setFirstDate(response.first);
                    setLastDate(response.last);
                    setReportData(response.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                Alert.alert(error.message);
                setLoading(false);
            });
    };

    const prevData = function () {
        // this.setState({
        //     period: this.state.period - 1
        // });
        setReportData([]);
        getAttendance(username, period - 1);
        setPeriod(period - 1);
    };

    const nextData = function () {
        setReportData([]);
        getAttendance(username, period + 1);
        setPeriod(period + 1);
    };

    return (
        <View
            style={{
                flex: 1,
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "flex-start",
                backgroundColor: "#f0f0f0",
            }}
        >
            <StatusBar
                backgroundColor="#1151a5"
                barStyle="light-content"
                translucent={true}
            />
            <ScrollView
                style={{
                    flex: 1,
                }}
            >
                <View
                    style={{
                        flex: 1,
                        padding: 15,
                    }}
                >
                    <View
                        style={{
                            backgroundColor: "#fff",
                            minHeight: 60,
                            alignItems: "center",
                            paddingVertical: 20,
                            marginBottom: 15,
                            borderRadius: 5,
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 1,
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 2,
                            elevation: 5,
                        }}
                    >
                        <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                            Attendance Report
                        </Text>
                        <Text>
                            {firstDate} to {lastDate}
                        </Text>
                    </View>
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 15,
                        }}
                    >
                        <View style={{ justifyContent: "center" }}>
                            <Button
                                onPress={() => prevData()}
                                titleStyle={{ fontSize: 12 }}
                                title="Prev"
                                icon={{
                                    name: "arrow-left",
                                    size: 15,
                                    color: "white",
                                    type: "feather",
                                }}
                                disabled={
                                    loading
                                        ? true
                                        : period == prevDisabledPeriod
                                        ? true
                                        : false
                                }
                                loading={loading}
                            />
                        </View>
                        <View style={{ justifyContent: "center" }}>
                            <Button
                                onPress={() => nextData()}
                                titleStyle={{ fontSize: 12 }}
                                title="Next"
                                icon={{
                                    name: "arrow-right",
                                    size: 15,
                                    color: "white",
                                    type: "feather",
                                }}
                                iconRight={true}
                                disabled={
                                    loading ? true : period >= 0 ? true : false
                                }
                                loading={loading}
                            />
                        </View>
                    </View>
                    <View
                        style={{
                            display: loading ? "flex" : "none",
                            backgroundColor: "#fff",
                            minHeight: 60,
                            alignItems: "center",
                            paddingVertical: 20,
                            marginBottom: 15,
                            borderRadius: 5,
                            borderBottomWidth: 1,
                            borderBottomColor: "#d3d3d3",
                        }}
                    >
                        <Text>Loading ...</Text>
                    </View>
                    <FlatList
                        scrollEnabled={false}
                        data={reportData}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item }) => {
                            const key = item.id;
                            const colorIcon =
                                item.status == "OFF" ? "#e8291a" : "#1a73e9";
                            return (
                                <View
                                    style={{
                                        backgroundColor: "#fff",
                                        minHeight: 60,
                                        flexDirection: "row",
                                        borderBottomWidth: 1,
                                        borderBottomColor: "#d3d3d3",
                                        borderRadius: 3,
                                        marginBottom: 3,
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 0.2,
                                            justifyContent: "center",
                                        }}
                                    >
                                        <AntDesign
                                            name="calendar"
                                            size={22}
                                            color={colorIcon}
                                            style={{
                                                fontSize: 22,
                                                alignSelf: "center",
                                            }}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            flex: 0.7,
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text>{item.date}</Text>
                                        <Text>
                                            In : {item.timein}{" "}
                                            &nbsp;&nbsp;-&nbsp;&nbsp; Out :{" "}
                                            {item.timeout}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 0.15,
                                            justifyContent: "center",
                                            paddingHorizontal: 3,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text>[{item.status}]</Text>
                                        <Text>&nbsp;</Text>
                                    </View>
                                </View>
                            );
                        }}
                    />
                </View>
            </ScrollView>
        </View>
    );
}
