// Settings.js
import { useFocusEffect } from "@react-navigation/core";
import React, { Component, useState, useCallback, useEffect } from "react";
import { StyleSheet, ScrollView, TouchableOpacity, View, Text, BackHandler, StatusBar, Linking } from "react-native";
import { Ionicons, AntDesign, Feather } from "@expo/vector-icons";
import Constants from "expo-constants";
import { setItem } from "../Utility";

export default function SettingsScreen({ navigation }) {
    const [routeName, setRouteName] = useState("Settings");
    const version = Constants.manifest.version;

    const openTermsConditions = async function () {
        let url = 'https://lgi.my.id/tc.html';
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(url);
        } else {
            Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    const openPrivacyPolicy = async function () {
        let url = 'https://lgi.my.id/privacy.html';
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(url);
        } else {
            Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    const logout = function() {
        setItem('username', '');
        setItem('loginDate', '');
        setItem('fullName', '');
        setItem('token', '');
        setItem('usernameRegister', '');

        navigation.navigate("Loading");
    }

    useFocusEffect(
        useCallback(() => {
            // console.log("Settings Screen was focused");
            setRouteName("Settings");
            return () => {
                // console.log("Settings Screen was unfocused");
            };
        }, [])
    );

    useEffect(() => {
        function handleBackButton() {
            if (routeName == "Home") {
                BackHandler.exitApp();
            } else {
                navigation.pop();
            }
            return true;
        }

        const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            handleBackButton
        );

        return () => backHandler.remove();
    }, [navigation]);

    return (
        <View style={style.container}>
            <ScrollView>
                <StatusBar backgroundColor="#1151a5" barStyle="light-content" translucent={true} />
                <View style={style.containerProfileContent}>
                    <TouchableOpacity
                        onPress={() => openTermsConditions()}
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            paddingHorizontal: 20,
                            paddingVertical: 15,
                            elevation: 1,
                            borderTopLeftRadius: 7,
                            borderTopRightRadius: 7,
                            backgroundColor: "white",
                        }}
                    >
                        {/* <Ionicons name="md-checkbox-outline" size={18} color="#333" style={{ width: 25, marginTop: 3 }} /> */}
                        <AntDesign name="checkcircleo" size={18} color="#333" style={{ width: 25, marginTop: 3 }} />
                        <Text
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Terms and Conditions
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => openPrivacyPolicy()}
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            paddingHorizontal: 20,
                            paddingVertical: 15,
                            elevation: 1,
                            backgroundColor: "white",
                            borderColor: "#eee",
                            borderTopWidth: 1,
                            borderBottomLeftRadius: 7,
                            borderBottomRightRadius: 7,
                        }}
                    >
                        {/* <Ionicons name="md-lock" size={18} color="#333" style={{ width: 25, marginTop: 3 }} /> */}
                        {/* <AntDesign name="lock" size={18} color="#333" style={{ width: 25, marginTop: 3 }} /> */}
                        <Feather name="lock" size={18} color="#333" style={{ width: 25, marginTop: 3 }} />
                        <Text
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Privacy Policy
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => logout()}
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            paddingHorizontal: 20,
                            paddingVertical: 15,
                            elevation: 1,
                            marginTop: 10,
                            marginBottom: 10,
                            borderRadius: 7,
                            backgroundColor: "white",
                        }}
                    >
                        {/* <Ionicons name="md-log-out" size={18} color="#d0312d" style={{ width: 25, marginTop: 3 }} /> */}
                        <AntDesign name="logout" size={18} color="#d0312d" style={{ width: 25, marginTop: 3 }} />
                        <Text
                            style={{
                                fontSize: 16,
                                color: "#d0312d",
                            }}
                        >
                            Logout
                        </Text>
                    </TouchableOpacity>
                </View>
                <View
                    style={{
                        flex: 1,
                        alignItems: "center",
                        marginTop: 25
                    }}
                >
                    <Text
                        style={{
                            color: "#aaa",
                        }}
                    >
                        {version}
                    </Text>
                </View>
            </ScrollView>
        </View>
    );
}

const style = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        backgroundColor: "#f0f0f0",
    },
    containerProfileHeader: {
        justifyContent: "center",
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: "#fff",
        paddingVertical: 25,
        backgroundColor: "#1a73e9",
        borderRadius: 5,
    },
    containerProfileContent: {
        justifyContent: "flex-start",
        alignItems: "stretch",
        borderRadius: 5,
        margin: 15,
        borderWidth: 1,
        borderColor: "#eee",
    },
    viewIconBack: {
        alignSelf: "flex-start",
        marginVertical: 10,
        marginHorizontal: 10,
    },
    iconNick: {
        borderRadius: 35,
        backgroundColor: "#aaa",
        width: 65,
        height: 65,
        marginRight: 10,
        marginTop: 15,
        justifyContent: "center",
        alignItems: "center",
    },
    nameDescription: {
        marginTop: 15,
        alignItems: "center",
    },
    textNick: {
        color: "#fff",
        fontSize: 25,
        alignItems: "center",
    },
    textName: {
        color: "#fff",
        fontSize: 22,
    },
    textJob: {
        color: "#fff",
        fontSize: 12,
        marginTop: 10,
    },
});
