// Loading.js
import React, { useState, useEffect, useCallback } from "react";
import {
    TouchableOpacity,
    Image,
    ActivityIndicator,
    View,
    Text,
    Alert,
} from "react-native";
import Constants from "expo-constants";
import * as Device from "expo-device";
import { SafeAreaView } from "react-native-safe-area-context";
import { httpBuildQuery, getItem, setItem } from "../Utility";
import { useFocusEffect } from "@react-navigation/core";

export default function Loading({ navigation }) {
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorStatusCode, setErrorStatusCode] = useState("");

    useFocusEffect(
        useCallback(() => {
            // console.log("Screen was focused");
            checkVersion();

            return () => {
                // console.log("Screen was unfocused");
                // Useful for cleanup functions
            };
        }, [])
    );

    const checkVersion = function () {
        fetch(`https://lgi.my.id/sso/index.php`, {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: httpBuildQuery({
                installationId: Constants.installationId,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status);
                }
                return response.json();
            })
            .then((response) => {
                if (Constants.manifest.version < response.version) {
                    if (response.force_update == true) {
                        Alert.alert(
                            "New Version",
                            `A new version of Attendance App is available.\nPlease update to version ${response.version} now.`,
                            [
                                {
                                    text: "Update",
                                    onPress: () => updateApp(),
                                },
                            ],
                            { cancelable: false }
                        );
                    } else {
                        Alert.alert(
                            "New Version",
                            `A new version of Attendance App is available.\nPlease update to version ${response.version} now.`,
                            [
                                {
                                    text: "Update",
                                    onPress: () => updateApp(),
                                },
                                {
                                    text: "Not now",
                                    onPress: () => sendData(),
                                    style: "cancel",
                                },
                            ],
                            { cancelable: false }
                        );
                    }
                } else {
                    sendData();
                }
            })
            .catch((error) => {
                setLoading(false);
                setShowError(true);
                setErrorStatusCode(error.message);
                Alert.alert(
                    `Info - ${error.message}`,
                    `The service is currently unavailable, please try again.`
                );
            });
    };

    const updateApp = async function () {
        let url = "https://lgi.my.id/sso/index.php/home/update";
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(url);
        } else {
            Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    };

    const insertDevice = function(nip) {
        let jsonData = {
            nip: nip,
            installationId: Constants.installationId,
            deviceName: Device.deviceName,
            isDevice: Device.isDevice,
            brand: Device.brand,
            manufacturer: Device.manufacturer,
            modelName: Device.modelName,
            deviceYearClass: Device.deviceYearClass,
            totalMemory: Device.totalMemory,
            osName: Device.osName,
            osVersion: Device.osVersion,
            osBuildId: Device.osBuildId,
        }

        fetch(`https://lgi.my.id/sso/index.php/login/insert_device`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: httpBuildQuery(jsonData),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (result.valid == true) {
                    // this.setState({ loading: false });
                    // Actions.register();
                } else {
                    Alert.alert('Error', 'Something error, please contact Administrator.');
                }
            }).catch((error) => {
                Alert.alert('Error', error.message);
            });
    }

    const sendData = function () {
        getItem("username")
            .then((result) => {
                return result;
            })
            .then((result) => {
                // // console.log(result, result.length);
                if (result) {
                    setLoading(false);
                    insertDevice(result);
                    // Actions.home();
                    navigation.replace("Home");
                } else {
                    setLoading(false);
                    setShowError(false);
                    navigation.replace("Register");
                }
            });
    };

    return (
        <SafeAreaView
            style={{
                flex: 1,
                justifyContent: "center",
                backgroundColor: "white",
                alignItems: "center",
            }}
        >
            <View
                style={{
                    display: showError ? "flex" : "none",
                    flex: 1,
                    justifyContent: "center",
                    backgroundColor: "white",
                    alignItems: "center",
                }}
            >
                <Text style={{ fontWeight: "bold" }}>{errorStatusCode}</Text>
                <Text style={{ textAlign: "center" }}>
                    The service is currently unavailable.{"\n"}
                    Please make sure you have internet connection and try again.
                </Text>
                <TouchableOpacity
                    onPress={() => {
                        checkVersion();
                    }}
                    style={{
                        fontSize: 30,
                        backgroundColor: "#1a73e8",
                        color: "#1a73e9",
                        marginTop: 10,
                        paddingHorizontal: 15,
                        paddingVertical: 10,
                        borderRadius: 5,
                        overflow: "hidden",
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        elevation: 5,
                    }}
                >
                    <Text style={{ color: "white" }}>Try Again</Text>
                </TouchableOpacity>
            </View>
            <View
                style={{
                    display: showError == false ? "flex" : "none",
                    flex: 1,
                    justifyContent: "center",
                    backgroundColor: "white",
                    alignItems: "center",
                }}
            >
                <Image
                    style={{
                        width: 250,
                        height: 250,
                        backgroundColor: "#fff",
                        overflow: "hidden",
                        borderRadius: 50,
                        marginVertical: 10,
                    }}
                    source={require("../images/icon.png")}
                />
                <ActivityIndicator
                    size="large"
                    color="#0000b3"
                    style={{ display: loading ? "none" : "flex" }}
                />
            </View>
        </SafeAreaView>
    );
}
