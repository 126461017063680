import React, { useState, useEffect, useRef } from "react";
import { BackHandler } from "react-native";
import {
    NavigationContainer,
    useNavigationState,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Home from "./src/pages/Home";
import Loading from "./src/pages/Loading";
import Report from "./src/pages/Report";
import Settings from "./src/pages/Settings";
import Register from "./src/pages/Register";
import Verification from "./src/pages/Verification";
import TakePicture from "./src/pages/TakePicture";

const Stack = createNativeStackNavigator();

export default function App() {

    useEffect(() => {

        return () => {
        };
    }, []);

    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName="Loading">
                <Stack.Screen
                    options={{
                        headerShown: false,
                    }}
                    name="Loading"
                    component={Loading}
                />
                <Stack.Screen
                    name="Home"
                    component={Home}
                    options={{
                        gestureEnabled: false,
                        headerShown: false,
                        headerLeft: () => <></>,
                    }}
                />
                <Stack.Screen
                    name="Register"
                    component={Register}
                    options={{
                        gestureEnabled: false,
                        // headerShown: false,
                        headerLeft: () => <></>,
                        headerStyle: {
                            backgroundColor: "#1151a5",
                        },
                        headerTintColor: "#fff",
                        headerTitle: "Register Device",
                    }}
                />
                <Stack.Screen
                    name="Report"
                    component={Report}
                    options={{
                        headerStyle: {
                            backgroundColor: "#1151a5",
                        },
                        headerTintColor: "#fff",
                    }}
                />
                <Stack.Screen
                    name="TakePicture"
                    component={TakePicture}
                    options={{
                        unmountOnBlur: true,
                        headerShown: false,
                        headerStyle: {
                            backgroundColor: "#1151a5",
                        },
                        headerTintColor: "#fff",
                    }}
                />
                <Stack.Screen
                    name="Verification"
                    component={Verification}
                    options={{
                        headerStyle: {
                            backgroundColor: "#1151a5",
                        },
                        headerTintColor: "#fff",
                    }}
                />
                <Stack.Screen
                    name="Settings"
                    component={Settings}
                    options={{
                        headerStyle: {
                            backgroundColor: "#1151a5",
                        },
                        headerTintColor: "#fff",
                    }}
                />
            </Stack.Navigator>
        </NavigationContainer>
    );
}
