// Register.js
import { useFocusEffect } from "@react-navigation/core";
import React, { Component, useState, useCallback, useEffect } from "react";
import {
    TextInput,
    TouchableOpacity,
    View,
    Text,
    BackHandler,
    StatusBar,
    StyleSheet,
    Alert
} from "react-native";
import { httpBuildQuery, setItem } from "../Utility";
import Constants from "expo-constants";
import * as Device from 'expo-device';

export default function RegisterScreen({ navigation }) {
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonName, setButtonName] = useState("Register");
    const [routeName, setRouteName] = useState("Register");

    const sendData = function() {
        setButtonDisabled(true);
        setButtonName("Processing ...");
        fetch(`https://lgi.my.id/sso/index.php/login/register_device_test?_=${new Date()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: httpBuildQuery({
                username: username,
                email: email,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                // console.log(response);
                if (response.valid === true) {
                    setItem('usernameRegister', username);
                    insertDevice(username);
                    navigation.navigate("Verification");
                } else {
                    Alert.alert('Info', response.message);
                }
                // this.setState({ buttonDisabled: false });
                // if (response.length > 0) {
                //     setItem('username', response[0].IDEmployee);
                //     setItem('name', response[0].FullName);

                //     this.insertDevice(response[0].IDEmployee);
                // } else {
                //     Alert.alert('Info', 'NIP (Employee ID) not found.');
                // }

                setTimeout(function () {
                    setButtonName("Register");
                    setButtonDisabled(false);
                }, 1000);
            }).catch((error) => {
                Alert.alert('Error', error.message);
                setButtonDisabled(false);
                setButtonName("Register");
            });
    }

    const insertDevice = function (nip) {
        let jsonData = {
            nip: nip,
            installationId: Constants.installationId,
            deviceName: Device.deviceName,
            isDevice: Device.isDevice,
            brand: Device.brand,
            manufacturer: Device.manufacturer,
            modelName: Device.modelName,
            deviceYearClass: Device.deviceYearClass,
            totalMemory: Device.totalMemory,
            osName: Device.osName,
            osVersion: Device.osVersion,
            osBuildId: Device.osBuildId,
        }

        fetch(`https://lgi.my.id/sso/index.php/login/insert_device`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: httpBuildQuery(jsonData),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (result.valid == true) {
                    // Actions.home();
                } else {
                    Alert.alert('Error', 'Something error, please contact Administrator.');
                }
            }).catch((error) => {
                Alert.alert('Error Insert Device', error.message);
            });
    }

    useFocusEffect(
        useCallback(() => {
            // console.log("Register Screen was focused");
            setRouteName("Register");
            return () => {
                // console.log("Register Screen was unfocused");
            };
        }, [])
    );

    useEffect(() => {
        // function handleBackButton() {
        //     if (routeName == "Home" || routeName == "Register") {
        //         BackHandler.exitApp();
        //     } else {
        //         navigation.pop();
        //     }
        //     return true;
        // }

        // const backHandler = BackHandler.addEventListener(
        //     "hardwareBackPress",
        //     handleBackButton
        // );

        // return () => backHandler.remove();
        return () => {};
    }, [navigation]);

    return (
        <View style={styles.containerRegister}>
            <StatusBar
                backgroundColor="#1151a5"
                barStyle="light-content"
                translucent={true}
            />
            <View style={styles.containerFormRegister}>
                <Text style={styles.textLabelRegister}>NIP (Employee ID)</Text>
                <TextInput
                    style={styles.inputBoxRegister}
                    underlineColorAndroid="rgba(0,0,0,0)"
                    name="username"
                    placeholder="Enter your NIP (Employee ID)"
                    placeholderTextColor="#202124"
                    keyboardType="number-pad"
                    autoFocus={true}
                    onChangeText={(username) => setUsername(username)}
                />
                <Text style={styles.textLabelRegister}>Email Address</Text>
                <TextInput
                    style={styles.inputBoxRegister}
                    underlineColorAndroid="rgba(0,0,0,0)"
                    name="email"
                    placeholder="Enter your Email Address"
                    placeholderTextColor="#202124"
                    // keyboardType="number-pad"
                    onChangeText={(email) => setEmail(email)}
                />
                <TouchableOpacity
                    onPress={() => sendData()}
                    disabled={buttonDisabled}
                    style={styles.buttonRegister}
                    pointerEvents={buttonDisabled ? "none" : "all"}
                >
                    <Text style={styles.buttonText}>{buttonName}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    containerRegister: {
        backgroundColor: "#ffffff",
        flex: 1,
        textAlign: "left",
    },
    containerFormRegister: {
        flexGrow: 1,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        textAlign: "left",
    },
    textLabelRegister: {
        fontSize: 15,
        textAlign: "left",
        marginTop: 10,
        marginBottom: 0,
        marginHorizontal: 15,
    },
    inputBoxRegister: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderRadius: 4,
        paddingHorizontal: 16,
        paddingVertical: 13,
        fontSize: 16,
        color: "#202124",
        marginVertical: 15,
        marginHorizontal: 15,
        borderWidth: 1,
        borderColor: "#ddd",
        alignSelf: "stretch",
    },
    textLabels: {
        fontSize: 15,
        textAlign: "left",
        marginTop: 0,
        marginBottom: 15,
        marginHorizontal: 15,
    },
    buttonRegister: {
        backgroundColor: "#1a73e9",
        borderRadius: 4,
        paddingHorizontal: 26,
        paddingVertical: 13,
        marginBottom: 10,
        marginHorizontal: 15,
    },
    buttonOpacity: {
        backgroundColor: "#1a73e9",
        borderRadius: 4,
        paddingHorizontal: 26,
        paddingVertical: 13,
        marginBottom: 10,
        marginHorizontal: 15,
        opacity: 0.7,
    },
    buttonText: {
        fontSize: 16,
        fontWeight: "500",
        color: "#ffffff",
        textAlign: "center",
    },
});
