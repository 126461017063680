// import AsyncStorage from '@react-native-community/async-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { AsyncStorage } from "react-native"


export const setItem = async (item, selectedValue) => {
    try {
        await AsyncStorage.setItem(item, selectedValue);
        return true;
    } catch (error) {
        console.error('AsyncStorage error: ' + error.message);
    }
}

export const getItem = async (item) => {
    try {
        var value = await AsyncStorage.getItem(item);
        if (value === null) {
            value = '';
        }
        return value;
    } catch (error) {
        console.error('AsyncStorage error: ' + error.message);
        return false;
    }
}

export const httpBuildQuery = function (formdata, numericPrefix, argSeparator, encType) { // eslint-disable-line camelcase
    //  discuss at: http://locutus.io/php/http_build_query/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Legaev Andrey
    // improved by: Michael White (http://getsprink.com)
    // improved by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Brett Zamir (http://brett-zamir.me)
    //  revised by: stag019
    //    input by: Dreamer
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    // bugfixed by: MIO_KODUKI (http://mio-koduki.blogspot.com/)
    // improved by: Will Rowe
    //      note 1: If the value is null, key and value are skipped in the
    //      note 1: http_build_query of PHP while in locutus they are not.
    //   example 1: http_build_query({foo: 'bar', php: 'hypertext processor', baz: 'boom', cow: 'milk'}, '', '&amp;')
    //   returns 1: 'foo=bar&amp;php=hypertext+processor&amp;baz=boom&amp;cow=milk'
    //   example 2: http_build_query({'php': 'hypertext processor', 0: 'foo', 1: 'bar', 2: 'baz', 3: 'boom', 'cow': 'milk'}, 'myvar_')
    //   returns 2: 'myvar_0=foo&myvar_1=bar&myvar_2=baz&myvar_3=boom&php=hypertext+processor&cow=milk'
    //   example 3: http_build_query({foo: 'bar', php: 'hypertext processor', baz: 'boom', cow: 'milk'}, '', '&amp;', 'PHP_QUERY_RFC3986')
    //   returns 3: 'foo=bar&amp;php=hypertext%20processor&amp;baz=boom&amp;cow=milk'

    var encodeFunc

    switch (encType) {
        case 'PHP_QUERY_RFC3986':
            //       discuss at: http://locutus.io/php/rawurlencode/
            //      original by: Brett Zamir (http://brett-zamir.me)
            //         input by: travc
            //         input by: Brett Zamir (http://brett-zamir.me)
            //         input by: Michael Grier
            //         input by: Ratheous
            //      bugfixed by: Kevin van Zonneveld (http://kvz.io)
            //      bugfixed by: Brett Zamir (http://brett-zamir.me)
            //      bugfixed by: Joris
            // reimplemented by: Brett Zamir (http://brett-zamir.me)
            // reimplemented by: Brett Zamir (http://brett-zamir.me)
            //           note 1: This reflects PHP 5.3/6.0+ behavior
            //           note 1: Please be aware that this function expects \
            //           note 1: to encode into UTF-8 encoded strings, as found on
            //           note 1: pages served as UTF-8
            //        example 1: rawurlencode('Kevin van Zonneveld!')
            //        returns 1: 'Kevin%20van%20Zonneveld%21'
            //        example 2: rawurlencode('http://kvz.io/')
            //        returns 2: 'http%3A%2F%2Fkvz.io%2F'
            //        example 3: rawurlencode('http://www.google.nl/search?q=Locutus&ie=utf-8')
            //        returns 3: 'http%3A%2F%2Fwww.google.nl%2Fsearch%3Fq%3DLocutus%26ie%3Dutf-8'

            encodeFunc = function (str) {
                str = (str + '')

                // Tilde should be allowed unescaped in future versions of PHP (as reflected below),
                // but if you want to reflect current
                // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
                return encodeURIComponent(str)
                    .replace(/!/g, '%21')
                    .replace(/'/g, '%27')
                    .replace(/\(/g, '%28')
                    .replace(/\)/g, '%29')
                    .replace(/\*/g, '%2A')
            }
            break

        case 'PHP_QUERY_RFC1738':
        default:
            //       discuss at: http://locutus.io/php/urlencode/
            //      original by: Philip Peterson
            //      improved by: Kevin van Zonneveld (http://kvz.io)
            //      improved by: Kevin van Zonneveld (http://kvz.io)
            //      improved by: Brett Zamir (http://brett-zamir.me)
            //      improved by: Lars Fischer
            //      improved by: Waldo Malqui Silva (https://fayr.us/waldo/)
            //         input by: AJ
            //         input by: travc
            //         input by: Brett Zamir (http://brett-zamir.me)
            //         input by: Ratheous
            //      bugfixed by: Kevin van Zonneveld (http://kvz.io)
            //      bugfixed by: Kevin van Zonneveld (http://kvz.io)
            //      bugfixed by: Joris
            // reimplemented by: Brett Zamir (http://brett-zamir.me)
            // reimplemented by: Brett Zamir (http://brett-zamir.me)
            //           note 1: This reflects PHP 5.3/6.0+ behavior
            //           note 1: Please be aware that this function
            //           note 1: expects to encode into UTF-8 encoded strings, as found on
            //           note 1: pages served as UTF-8
            //        example 1: urlencode('Kevin van Zonneveld!')
            //        returns 1: 'Kevin+van+Zonneveld%21'
            //        example 2: urlencode('http://kvz.io/')
            //        returns 2: 'http%3A%2F%2Fkvz.io%2F'
            //        example 3: urlencode('http://www.google.nl/search?q=Locutus&ie=utf-8')
            //        returns 3: 'http%3A%2F%2Fwww.google.nl%2Fsearch%3Fq%3DLocutus%26ie%3Dutf-8'

            encodeFunc = function (str) {
                str = (str + '')

                return encodeURIComponent(str)
                    .replace(/!/g, '%21')
                    .replace(/'/g, '%27')
                    .replace(/\(/g, '%28')
                    .replace(/\)/g, '%29')
                    .replace(/\*/g, '%2A')
                    .replace(/~/g, '%7E')
                    .replace(/%20/g, '+')
            }
            break
    }

    var value
    var key
    var tmp = []

    var _httpBuildQueryHelper = function (key, val, argSeparator) {
        var k
        var tmp = []
        if (val === true) {
            val = '1'
        } else if (val === false) {
            val = '0'
        }
        if (val !== null) {
            if (typeof val === 'object') {
                for (k in val) {
                    if (val[k] !== null) {
                        tmp.push(_httpBuildQueryHelper(key + '[' + k + ']', val[k], argSeparator))
                    }
                }
                return tmp.join(argSeparator)
            } else if (typeof val !== 'function') {
                return encodeFunc(key) + '=' + encodeFunc(val)
            } else {
                throw new Error('There was an error processing for http_build_query().')
            }
        } else {
            return ''
        }
    }

    if (!argSeparator) {
        argSeparator = '&'
    }
    for (key in formdata) {
        value = formdata[key]
        if (numericPrefix && !isNaN(key)) {
            key = String(numericPrefix) + key
        }
        var query = _httpBuildQueryHelper(key, value, argSeparator)
        if (query !== '') {
            tmp.push(query)
        }
    }

    return tmp.join(argSeparator)
}