// Verification.js
import { useFocusEffect } from "@react-navigation/core";
import React, { Component, useState, useCallback, useEffect } from "react";
import {
    TextInput,
    TouchableOpacity,
    View,
    Text,
    BackHandler,
    StatusBar,
    StyleSheet,
    Alert
} from "react-native";
import { getItem, setItem, httpBuildQuery } from "../Utility";
import Constants from "expo-constants";
import * as Device from "expo-device";

export default function VerificationScreen({ navigation }) {
    const [username, setUsername] = useState(null);
    const [code, setCode] = useState(null);
    const [routeName, setRouteName] = useState("Verification");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonName, setButtonName] = useState("Submit");

    const sendData = function () {
        setButtonName("Processing ...");
        setButtonDisabled(true);

        fetch(`https://lgi.my.id/sso/index.php/login/check_verification`, {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: httpBuildQuery({
                username: username,
                code: code,
                device_id: Device.deviceName,
                unique_id: Constants.installationId,
                _: Date.now(),
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.valid === true) {
                    setItem("username", username);
                    setItem("loginDate", response.last_login.RegDate);
                    setItem("fullName", response.last_login.FullName);
                    setItem("token", response.token);
                    setItem("usernameRegister", "");

                    navigation.replace("Home");
                } else {
                    Alert.alert("Info", response.message);
                }

                setTimeout(function () {
                    setButtonName("Submit");
                    setButtonDisabled(false);
                }, 1000);

                return response;
            })
            .catch((error) => {
                Alert.alert("Error", error.message);
                setButtonName("Submit");
                setButtonDisabled(false);
            });
    };

    useFocusEffect(
        useCallback(() => {
            // console.log("Verification Screen was focused");
            setRouteName("Verification");
            getItem("usernameRegister").then(function (result) {
                setUsername(result);
            });
            return () => {
                // console.log("Verification Screen was unfocused");
            };
        }, [])
    );

    useEffect(() => {
        getItem("usernameRegister").then(function (result) {
            setUsername(result);
        });

        // function handleBackButton() {
        //     if (routeName == "Home" || routeName == "Verification") {
        //         BackHandler.exitApp();
        //     } else {
        //         navigation.pop();
        //     }
        //     return true;
        // }

        // const backHandler = BackHandler.addEventListener(
        //     "hardwareBackPress",
        //     handleBackButton
        // );

        // return () => backHandler.remove();
        return () => {};
    }, [navigation]);

    return (
        <View style={styles.containerVerification}>
            <StatusBar
                backgroundColor="#1151a5"
                barStyle="light-content"
                translucent={true}
            />
            <View style={styles.containerFormVerification}>
                <Text style={styles.textLabelVerification}>
                    Enter verification code
                </Text>
                <TextInput
                    style={styles.inputBoxVerification}
                    underlineColorAndroid="rgba(0,0,0,0)"
                    name="code"
                    placeholder="Enter your verification code"
                    placeholderTextColor="#202124"
                    keyboardType="number-pad"
                    autoFocus={true}
                    onChangeText={(code) => setCode(code)}
                />
                <Text style={styles.textLabels}>
                    We have sent a verification code to your email.
                </Text>
                <TouchableOpacity
                    onPress={() => sendData()}
                    disabled={buttonDisabled}
                    style={styles.buttonVerification}
                    pointerEvents={buttonDisabled ? "none" : "all"}
                >
                    <Text style={styles.buttonText}>{buttonName}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    containerVerification: {
        backgroundColor: "#ffffff",
        flex: 1,
        textAlign: "left",
    },
    containerFormVerification: {
        flexGrow: 1,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        textAlign: "left",
    },
    textLabelVerification: {
        fontSize: 15,
        textAlign: "left",
        marginTop: 10,
        marginBottom: 0,
        marginHorizontal: 15,
    },
    inputBoxVerification: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderRadius: 4,
        paddingHorizontal: 16,
        paddingVertical: 13,
        fontSize: 16,
        color: "#202124",
        marginVertical: 15,
        marginHorizontal: 15,
        borderWidth: 1,
        borderColor: "#ddd",
        alignSelf: "stretch",
    },
    textLabels: {
        fontSize: 15,
        textAlign: "left",
        marginTop: 0,
        marginBottom: 15,
        marginHorizontal: 15,
    },
    buttonVerification: {
        backgroundColor: "#1a73e9",
        borderRadius: 4,
        paddingHorizontal: 26,
        paddingVertical: 13,
        marginBottom: 10,
        marginHorizontal: 15,
    },
    buttonOpacity: {
        backgroundColor: "#1a73e9",
        borderRadius: 4,
        paddingHorizontal: 26,
        paddingVertical: 13,
        marginBottom: 10,
        marginHorizontal: 15,
        opacity: 0.7,
    },
    buttonText: {
        fontSize: 16,
        fontWeight: "500",
        color: "#ffffff",
        textAlign: "center",
    },
});
