// Home.js
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Alert,
    View,
    Text,
    BackHandler,
    StyleSheet,
    ScrollView,
    TouchableOpacity,
    StatusBar,
    Platform,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import * as Location from "expo-location";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import * as Permissions from "expo-permissions";
import {
    Ionicons,
    AntDesign,
    FontAwesome,
    MaterialCommunityIcons,
} from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/core";
import date from "locutus/php/datetime/date";
import strtotime from "locutus/php/datetime/strtotime";
import useAppState from "react-native-appstate-hook";
import { getItem, setItem, httpBuildQuery } from "../Utility";

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

export default function Home({ navigation }) {
    const [routeName, setRouteName] = useState("Home");
    const [dateNow, setDateNow] = useState(date("l, M d, Y"));
    const [location, setLocation] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [latlng, setLatLng] = useState("");
    const [isMocked, setIsMocked] = useState(false);
    const [locationName, setLocationName] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [tapType, setTapType] = useState(null);

    const [username, setUsername] = useState("");
    const [fullname, setFullname] = useState("");
    const [tapIn, setTapIn] = useState("");
    const [tapOut, setTapOut] = useState("");

    const [firstDate, setFirstDate] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [present, setPresent] = useState(0);
    const [absent, setAbsent] = useState(0);
    const [leave, setLeave] = useState(0);
    const [travel, setTravel] = useState(0);

    const [expoPushToken, setExpoPushToken] = useState("");
    const [notification, setNotification] = useState(false);
    const notificationListener = useRef();
    const responseListener = useRef();

    // const [appState, setAppState] = useState(AppState.currentState);
    const { appState } = useAppState({
        onChange: (newAppState) => {
            // console.log("App state changed to ", newAppState)
        },
        onForeground: () => {
            // console.log("App went to Foreground");
            setDateNow(date("l, M d, Y"));
        },
        onBackground: () => {
            // console.log("App went to background");
        },
    });

    useFocusEffect(
        useCallback(() => {
            // console.log("Home Screen was focused");
            setRouteName("Home");
            setDateNow(date("l, M d, Y"));

            getItem("username").then(function (result) {
                // console.log("USERNAME", result);
                setUsername(result);

                updateClock(result);
                getLocation();
                setIsProcessing(false);
            });

            getItem("fullName").then(function (result) {
                // console.log("FULLNAME", result);
                setFullname(result);
            });

            // getIP();

            return () => {
                // console.log("Home Screen was unfocused");
            };
        }, [])
    );

    const getIP = async function () {
        fetch("https://api.ipify.org/?format=json", {
            method: "GET",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: httpBuildQuery({}),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                // console.log(response);
                return response;
            })
            .catch((error) => {
                Alert.alert("Error", error.message);
            });
    };

    useEffect(() => {
        // function handleBackButton() {
        //     if (
        //         routeName == "Loading" ||
        //         routeName == "Home" ||
        //         routeName == "Register"
        //     ) {
        //         BackHandler.exitApp();
        //     }
        //     return true;
        // }

        // const backHandler = BackHandler.addEventListener(
        //     "hardwareBackPress",
        //     handleBackButton
        // );

        registerForPushNotificationsAsync().then((token) =>
            setExpoPushToken(token)
        );

        notificationListener.current =
            Notifications.addNotificationReceivedListener((notification) => {
                setNotification(notification);
            });

        responseListener.current =
            Notifications.addNotificationResponseReceivedListener(
                (response) => {
                    console.log(response);
                }
            );

        return () => {
            // on unmount
            // AppState.removeEventListener("change", handleAppStateChange);
            // backHandler.remove();

            Notifications.removeNotificationSubscription(
                notificationListener.current
            );
            Notifications.removeNotificationSubscription(
                responseListener.current
            );
        };
    }, [
        navigation,
        dateNow,
        location,
        errorMsg,
        latlng,
        locationName,
        isConnecting,
        isConnected,
        isProcessing,
        tapType,
        username,
        fullname,
        tapIn,
        tapOut,
    ]);

    const getLocation = async (isTap = false) => {
        setLocationName(null);
        setLatLng("-");

        setTimeout(function () {
            if (isTap === 1 || isTap === 0) {
                // console.log(lat);
                // console.log(lng);
                insertTap(isTap, location);
            }
        }, 1500);
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== "granted") {
            setErrorMsg("Permission to access location was denied");
            return;
        }

        let loc = await Location.getCurrentPositionAsync({});
        setLocation(loc);

        if (errorMsg) {
            locationName = errorMsg;
        } else if (loc) {
            // text = JSON.stringify(loc);
            setLat(loc.coords.latitude);
            setLng(loc.coords.longitude);
            setLatLng(loc.coords.latitude + ", " + loc.coords.longitude);
            setIsMocked(loc.mocked);

            getLocationName(loc);
        }
    };

    const getLocationName = function (location) {
        if (Platform.OS == "android" || Platform.OS == "ios") {
            Location.reverseGeocodeAsync(location.coords).then((result) => {
                let locationNames = "";
                let locationArr = [];
                if (typeof result[0].street != "undefined") {
                    if (result[0].street != "" && result[0].street != null) {
                        locationNames += result[0].street;
                        locationArr.push(result[0].street);
                    }
                }
                if (typeof result[0].district != "undefined") {
                    if (
                        result[0].district != "" &&
                        result[0].district != null
                    ) {
                        locationNames += ", " + result[0].district;
                        locationArr.push(result[0].district);
                    }
                }
                if (typeof result[0].city != "undefined") {
                    if (result[0].city != "" && result[0].city != null) {
                        locationNames += ", " + result[0].city;
                        locationArr.push(result[0].city);
                    }
                }
                if (typeof result[0].subregion != "undefined") {
                    if (
                        result[0].subregion != "" &&
                        result[0].subregion != null
                    ) {
                        locationNames += ", " + result[0].subregion;
                        locationArr.push(result[0].subregion);
                    }
                }
                if (typeof result[0].region != "undefined") {
                    if (result[0].region != "" && result[0].region != null) {
                        locationNames += ", " + result[0].region;
                        locationArr.push(result[0].region);
                    }
                }
                if (typeof result[0].postalCode != "undefined") {
                    if (
                        result[0].postalCode != "" &&
                        result[0].postalCode != null
                    ) {
                        locationNames += ", " + result[0].postalCode;
                        locationArr.push(result[0].postalCode);
                    }
                }
                locationNames = locationArr.join(", ");
                setLocationName(locationNames);
                // setLocationName(locationNames + JSON.stringify(location));
                // setLocationName(JSON.stringify(location));
            });
        }
    };

    const updateClock = function (nip) {
        fetch("https://lgi.my.id/sso/index.php/attendance/home/get_clock", {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: httpBuildQuery({
                nip: nip,
                date: date("Y-m-d"),
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                // console.log(response);
                setTapIn("-");
                setTapOut("-");
                if (response.in != null) {
                    setTapIn(response.in);
                }
                if (response.out != null) {
                    setTapOut(response.out);
                }

                getAttendance(nip, 0);

                return response;
            })
            .catch((error) => {
                Alert.alert("Error", error.message);
            });
    };

    const updateToken = function (token) {
        fetch("https://lgi.my.id/sso/index.php/login/update_token", {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: httpBuildQuery({
                nip: username,
                username: username,
                installationId: Constants.installationId,
                token: token,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                Alert.alert("Error", error.message);
            });
    };

    const sendDataIn = function () {
        setItem("tapType", "1");
        setTapType(1);
        setIsProcessing(true);
        getLocation(1);
    };

    const sendDataOut = function () {
        setItem("tapType", "0");
        setTapType(0);
        setIsProcessing(true);
        getLocation(0);
    };

    const insertTap = function (tapType, location) {
        fetch("https://lgi.my.id/sso/index.php/attendance/home/insert_tap", {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: httpBuildQuery({
                username: username,
                type: tapType,
                date: date("Y-m-d H:i:s"),
                location: location,
                nip: username,
                installationId: Constants.installationId,
                deviceName: Device.deviceName,
                isDevice: Device.isDevice,
                brand: Device.brand,
                manufacturer: Device.manufacturer,
                modelName: Device.modelName,
                deviceYearClass: Device.deviceYearClass,
                totalMemory: Device.totalMemory,
                osName: Device.osName,
                osVersion: Device.osVersion,
                osBuildId: Device.osBuildId,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                // Actions.takePicture();
                // console.log(response);
                setIsProcessing(false);
                navigation.navigate("TakePicture");
                return response;
            })
            .catch((error) => {
                setIsProcessing(false);
                Alert.alert("Error", error.message);
            });
    };

    const getAttendance = function (username, period) {
        setLoading(true);
        fetch(
            "https://lgi.my.id/sso/index.php/attendance/home/get_attendance",
            {
                method: "POST",
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: httpBuildQuery({
                    idemployee: username,
                    period: period,
                    _: Date.now(),
                }),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                let presentCount = 0;
                let absentCount = 0;
                let leaveCount = 0;
                let travelCount = 0;
                if (response.valid === true) {
                    setFirstDate(response.first);
                    setLastDate(response.last);
                    setLoading(false);

                    if (typeof response.data != "undefined") {
                        response.data.forEach((element) => {
                            if (
                                date("Y-m-d", strtotime(element.date)) !=
                                date("Y-m-d")
                            ) {
                                if (
                                    element.status == "P" ||
                                    element.status == "MP"
                                ) {
                                    presentCount++;
                                }
                                if (element.status == "A") {
                                    absentCount++;
                                }
                                if (
                                    element.status == "AL" ||
                                    element.status == "ALD" ||
                                    element.status == "CIR" ||
                                    element.status == "CL" ||
                                    element.status == "FML" ||
                                    element.status == "MRL" ||
                                    element.status == "MTL" ||
                                    element.status == "SL" ||
                                    element.status == "OL"
                                ) {
                                    leaveCount++;
                                }
                                if (element.status == "OT") {
                                    travelCount++;
                                }
                            }
                        });
                        setPresent(presentCount);
                        setAbsent(absentCount);
                        setLeave(leaveCount);
                        setTravel(travelCount);
                    }
                }
            })
            .catch((error) => {
                Alert.alert(error.message);
                setLoading(false);
            });
    };

    const registerForPushNotificationsAsync = async function () {
        let token;

        if (Platform.OS === "android") {
            await Notifications.setNotificationChannelAsync("attendance", {
                name: "attendance",
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: "#FF231F7C",
            });
        }

        if (Device.isDevice) {
            // const { status: existingStatus } = await Permissions.getAsync(
            //     Permissions.NOTIFICATIONS
            // );
            // let finalStatus = existingStatus;
            // if (existingStatus !== "granted") {
            //     const { status } = await Permissions.askAsync(
            //         Permissions.NOTIFICATIONS
            //     );
            //     finalStatus = status;
            // }
            const { status: existingStatus } =
                await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== "granted") {
                const { status } =
                    await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== "granted") {
                // Alert.alert("Failed to get push token for push notification!");
                console.log("Failed to get push token for push notification!");
                return;
            }
            token = (await Notifications.getExpoPushTokenAsync()).data;
            console.log(token);
            updateToken(token);
        } else {
            Alert.alert("Must use physical device for Push Notifications");
        }

        return token;
    };

    return (
        <View style={{ flex: 1, backgroundColor: "#f0f0f0" }}>
            <StatusBar
                backgroundColor="#1151a5"
                barStyle="light-content"
                translucent={true}
            />
            <ScrollView
                style={{
                    flex: 1,
                }}
                contentContainerStyle={{ flexGrow: 1 }}
            >
                <View style={{ position: "relative" }}>
                    <LinearGradient
                        colors={["#1151a5", "#1a73e8"]}
                        start={[0, 1]}
                        end={[1, 0]}
                        style={{
                            height: 200,
                            paddingVertical: 50,
                            width: "100%",
                        }}
                    >
                        <View
                            style={{
                                width: "90%",
                                alignSelf: "center",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 10,
                            }}
                        >
                            <View style={{ width: "75%" }}>
                                <Text
                                    numberOfLines={1}
                                    ellipsizeMode="tail"
                                    style={{
                                        color: "white",
                                        width: "100%",
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                    {fullname}
                                </Text>
                                <Text style={{ color: "white" }}>
                                    {username}
                                </Text>
                            </View>
                            <View style={{ width: "25%" }}>
                                <View
                                    style={{
                                        alignSelf: "flex-end",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Ionicons
                                        name="ios-notifications"
                                        type="feather"
                                        color="#fff"
                                        style={{
                                            display: "none",
                                            fontSize: 27,
                                            marginRight: 20,
                                        }}
                                    />
                                    <TouchableOpacity
                                        onPress={() => {
                                            navigation.navigate("Settings");
                                        }}
                                    >
                                        <AntDesign
                                            name="setting"
                                            size={27}
                                            color="white"
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View
                                    style={{
                                        display: "none",
                                        alignSelf: "flex-end",
                                        borderRadius: 30,
                                        width: 60,
                                        height: 60,
                                        backgroundColor: "white",
                                        shadowColor: "#000",
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.25,
                                        shadowRadius: 3.84,
                                        elevation: 5,
                                    }}
                                >
                                    <View
                                        style={{
                                            borderRadius: 30,
                                            width: 60,
                                            height: 60,
                                            backgroundColor: "white",
                                            shadowColor: "#000",
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.25,
                                            shadowRadius: 3.84,
                                            resizeMode: "cover",
                                        }}
                                        // source={{ uri: this.state.imageURL }}
                                    />
                                </View>
                            </View>
                        </View>
                    </LinearGradient>
                    <View
                        onLayout={(event) => {
                            // this.find_dimesions(event.nativeEvent.layout);
                        }}
                        style={{
                            backgroundColor: "white",
                            alignSelf: "center",
                            top: -65,
                            minHeight: 175,
                            width: "90%",
                            borderRadius: 5,
                            padding: 20,
                            position: "relative",
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 3.84,
                            elevation: 2,
                        }}
                    >
                        <View
                            style={{
                                marginBottom: 15,
                                flexDirection: "row",
                                justifyContent: "flex-start",
                            }}
                        >
                            <AntDesign
                                name="calendar"
                                size={24}
                                color="#1a73e9"
                                style={{
                                    fontSize: 15,
                                    backgroundColor: "#fff",
                                    color: "#1a73e9",
                                    paddingRight: 5,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                }}
                            />
                            <Text style={{ lineHeight: 20 }}>{dateNow}</Text>
                            <View
                                style={{
                                    display: "none",
                                    width: 7,
                                    height: 7,
                                    backgroundColor:
                                        isConnecting && !isConnected
                                            ? "blue"
                                            : isConnected
                                            ? "#28a745"
                                            : "#bd2130",
                                    position: "absolute",
                                    top: 7,
                                    right: 2,
                                    borderRadius: 5,
                                }}
                            ></View>
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <View
                                style={{
                                    alignSelf: "flex-start",
                                    width: "50%",
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: "center",
                                        color: "#333",
                                        fontSize: 14,
                                    }}
                                >
                                    Clock In
                                </Text>
                                <Text
                                    style={{
                                        textAlign: "center",
                                        color: "#333",
                                        fontWeight: "bold",
                                        fontSize: 36,
                                    }}
                                >
                                    {tapIn}
                                </Text>
                            </View>
                            <View
                                style={{
                                    alignSelf: "flex-start",
                                    width: "50%",
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: "center",
                                        color: "#333",
                                        fontSize: 14,
                                    }}
                                >
                                    Clock Out
                                </Text>
                                <Text
                                    style={{
                                        textAlign: "center",
                                        color: "#333",
                                        fontWeight: "bold",
                                        fontSize: 36,
                                    }}
                                >
                                    {tapOut}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={{
                                marginTop: 15,
                                flex: 1,
                                flexDirection: "row",
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => sendDataIn()}
                                disabled={isProcessing}
                                style={
                                    isProcessing
                                        ? styles.buttonOpacity
                                        : styles.button
                                }
                                pointerEvents={isProcessing ? "none" : "all"}
                            >
                                <Ionicons
                                    name="ios-log-in"
                                    size={28}
                                    color="white"
                                />
                                <Text style={styles.buttonText}>TAP IN</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => sendDataOut()}
                                disabled={isProcessing}
                                style={
                                    isProcessing
                                        ? styles.buttonOpacityRed
                                        : styles.buttonRed
                                }
                                pointerEvents={isProcessing ? "none" : "all"}
                            >
                                <Ionicons
                                    name="ios-log-out"
                                    size={28}
                                    color="white"
                                />
                                <Text style={styles.buttonText}>TAP OUT</Text>
                            </TouchableOpacity>
                        </View>
                        <View
                            style={{
                                marginTop: 10,
                                display: latlng ? "flex" : "none",
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Ionicons
                                    name="md-navigate"
                                    type="feather"
                                    color="#1a73e9"
                                    style={{
                                        fontSize: 15,
                                        backgroundColor: "#fff",
                                        color: "#1a73e9",
                                        paddingRight: 5,
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                    }}
                                />
                                {/* <Entypo
                                    style={{ lineHeight: 22, paddingRight: 5 }}
                                    name="location"
                                    size={16}
                                    color="#1a73e9"
                                /> */}
                                <Text style={{ fontSize: 13, lineHeight: 20 }}>
                                    Location
                                </Text>
                            </View>
                            <Text style={{ fontSize: 12, color: "#666" }}>
                                {latlng}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    textAlign: "justify",
                                    display: locationName ? "flex" : "none",
                                    marginTop: 3,
                                }}
                            >
                                {locationName}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            width: "90%",
                            alignSelf: "center",
                            top: -55,
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("Report");
                            }}
                            style={{
                                backgroundColor: "white",
                                borderRadius: 5,
                                padding: 15,
                                position: "relative",
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                elevation: 2,
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginBottom: 3,
                                }}
                            >
                                Attendance Summary
                            </Text>
                            {firstDate && lastDate ? (
                                <Text
                                    style={{
                                        textAlign: "center",
                                        marginBottom: 10,
                                    }}
                                >
                                    {firstDate} to {lastDate}
                                </Text>
                            ) : (
                                <Text
                                    style={{
                                        textAlign: "center",
                                        marginBottom: 10,
                                    }}
                                >
                                    Loading ...
                                </Text>
                            )}

                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        backgroundColor: "#f6f6f6",
                                        paddingHorizontal: 25,
                                        paddingVertical: 15,
                                        borderRadius: 5,
                                        margin: 10,
                                        flexDirection: "row",
                                    }}
                                >
                                    <FontAwesome
                                        style={{
                                            marginTop: 3,
                                            marginRight: 15,
                                        }}
                                        name="calendar-check-o"
                                        size={32}
                                        color="#28a745"
                                    />
                                    <View>
                                        <Text style={{ fontWeight: "bold" }}>
                                            Present
                                        </Text>
                                        <Text>{present} days</Text>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        backgroundColor: "#f6f6f6",
                                        paddingHorizontal: 25,
                                        paddingVertical: 15,
                                        borderRadius: 5,
                                        margin: 10,
                                        flexDirection: "row",
                                    }}
                                >
                                    <FontAwesome
                                        style={{
                                            marginTop: 3,
                                            marginRight: 15,
                                        }}
                                        name="calendar-times-o"
                                        size={32}
                                        color="#bd2130"
                                    />
                                    <View>
                                        <Text style={{ fontWeight: "bold" }}>
                                            Absent
                                        </Text>
                                        <Text>{absent} days</Text>
                                    </View>
                                </View>
                            </View>
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        backgroundColor: "#f6f6f6",
                                        paddingHorizontal: 25,
                                        paddingVertical: 15,
                                        borderRadius: 5,
                                        margin: 10,
                                        flexDirection: "row",
                                    }}
                                >
                                    <Ionicons
                                        style={{
                                            marginTop: 3,
                                            marginRight: 15,
                                        }}
                                        name="briefcase-outline"
                                        size={32}
                                        color="#1151a5"
                                    />
                                    <View>
                                        <Text style={{ fontWeight: "bold" }}>
                                            Leave
                                        </Text>
                                        <Text>{leave} days</Text>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        backgroundColor: "#f6f6f6",
                                        paddingHorizontal: 25,
                                        paddingVertical: 15,
                                        borderRadius: 5,
                                        margin: 10,
                                        flexDirection: "row",
                                    }}
                                >
                                    <MaterialCommunityIcons
                                        style={{
                                            marginTop: 3,
                                            marginRight: 15,
                                        }}
                                        name="airplane-marker"
                                        size={32}
                                        color="#1a73e8"
                                    />
                                    <View>
                                        <Text style={{ fontWeight: "bold" }}>
                                            Travel
                                        </Text>
                                        <Text>{travel} days</Text>
                                    </View>
                                </View>
                            </View>
                        </TouchableOpacity>
                        <Text
                            style={{
                                display: "none",
                                fontWeight: "bold",
                                marginBottom: 0,
                            }}
                        >
                            Menu
                        </Text>
                        <View
                            style={{
                                display: "none",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                marginLeft: -3,
                            }}
                        >
                            <View
                                style={{
                                    width: 65,
                                    height: 65,
                                    marginVertical: 15,
                                    marginRight: 15,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.navigate("Report");
                                    }}
                                    style={{
                                        fontSize: 30,
                                        backgroundColor: "#fff",
                                        color: "#1a73e9",
                                        paddingHorizontal: 20,
                                        paddingVertical: 15,
                                        borderRadius: 7,
                                        overflow: "hidden",
                                        shadowColor: "#000",
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.25,
                                        shadowRadius: 2,
                                        elevation: 3,
                                    }}
                                >
                                    <AntDesign
                                        name="calendar"
                                        size={24}
                                        color="#1a73e9"
                                    />
                                </TouchableOpacity>
                                <Text
                                    style={{
                                        fontSize: 10,
                                        color: "#333",
                                        marginTop: 10,
                                    }}
                                >
                                    Report
                                </Text>
                            </View>
                            {/*
                                <View
                                    style={{
                                        width: 65,
                                        height: 65,
                                        marginVertical: 15,
                                        marginRight: 15,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "none",
                                    }}
                                >
                                    <TouchableOpacity
                                        onPress={() => Actions.leavePermit()}
                                        style={{
                                            fontSize: 30,
                                            backgroundColor: "#fff",
                                            color: "#1a73e9",
                                            paddingHorizontal: 20,
                                            paddingVertical: 15,
                                            borderRadius: 7,
                                            overflow: "hidden",
                                            shadowColor: "#000",
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.25,
                                            shadowRadius: 2,
                                            elevation: 3,
                                        }}
                                    >
                                        <Ionicons
                                            name="ios-car"
                                            type="feather"
                                            color="#1a73e9"
                                            style={{
                                                fontSize: 30,
                                            }}
                                        />
                                    </TouchableOpacity>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                            color: "#333",
                                            marginTop: 10,
                                        }}
                                    >
                                        Leave Permit
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        width: 65,
                                        height: 65,
                                        marginVertical: 15,
                                        marginRight: 15,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "none",
                                    }}
                                >
                                    <TouchableOpacity
                                        onPress={() => Actions.incomplete()}
                                        style={{
                                            fontSize: 30,
                                            backgroundColor: "#fff",
                                            color: "#1a73e9",
                                            paddingHorizontal: 20,
                                            paddingVertical: 15,
                                            borderRadius: 7,
                                            overflow: "hidden",
                                            shadowColor: "#000",
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.25,
                                            shadowRadius: 2,
                                            elevation: 3,
                                        }}
                                    >
                                        <Ionicons
                                            name="ios-warning"
                                            type="feather"
                                            color="#1a73e9"
                                            style={{
                                                fontSize: 30,
                                            }}
                                        />
                                    </TouchableOpacity>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                            color: "#333",
                                            marginTop: 10,
                                        }}
                                    >
                                        Incomplete
                                    </Text>
                                </View>
                                 */}
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#ffffff",
        flex: 1,
    },
    rightContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    rightIcon: {
        marginHorizontal: 20,
    },
    textHeader: {
        fontSize: 20,
        color: "#fff",
        marginHorizontal: 15,
        fontWeight: "bold",
    },
    containerForm: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    logoImg: {
        width: 95,
        height: 90,
        backgroundColor: "#fff",
        overflow: "hidden",
        borderRadius: 50,
        marginVertical: 10,
    },
    logoText: {
        fontSize: 18,
        fontWeight: "300",
        color: "#333333",
    },
    containerFormButton: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    button: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#28a745",
        borderRadius: 4,
        marginRight: 5,
        paddingVertical: 13,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 3,
    },
    buttonRed: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#bd2130",
        borderRadius: 4,
        marginLeft: 5,
        paddingVertical: 13,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 3,
    },
    buttonText: {
        fontSize: 18,
        fontWeight: "500",
        color: "#ffffff",
        textAlign: "center",
        marginLeft: 10,
    },
    buttonOpacity: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#28a745",
        borderRadius: 4,
        marginRight: 5,
        paddingVertical: 13,
        opacity: 0.5,
    },
    buttonOpacityRed: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#fd1c00",
        borderRadius: 4,
        marginLeft: 5,
        paddingVertical: 13,
        opacity: 0.5,
    },
});
